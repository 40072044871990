import request from '@/utils/request'

export function jumpUrl(params) {
	return request({
		url: '/jump/get/url',
		method: 'post',
		params
	})
}

export function jumpCode(params) {
	return request({
		url: '/jump/get/code',
		method: 'post',
		params
	})
}