<template>
	<div class="nav-box">
		<img :src="code" />
		<div class="tip">扫描上方二维码进入阿克索健康小程序,点击体检报告解读上传体检报告</div>
		<div class="btn" @click="jump()">点我直接进入小程序</div>
	</div>
</template>

<script>
	import {
		jumpUrl,
		jumpCode
	} from '@/api/sys/jump'
	export default {
		data() {
			return {
				url: '',
				code: ''
			};
		},
		created() {
			this.getUrl()
			this.getCode()
		},
		methods: {
			getUrl() {
				jumpUrl({}).then(res => {
					this.url = res.data
				})
			},
			getCode() {
				jumpCode({}).then(res => {
					this.code = res.data
				})
			},
			jump() {
				let a = document.createElement('a') //创建一个a标签
				a.setAttribute("href", this.url); //添加URL
				a.setAttribute('target', "_blank") //设置target属性
				this.triggerEvent(a, 'click'); //触发事件
			},
			triggerEvent(el, evName) {
				if (typeof el[evName] == 'function') { //如果存在这个事件就直接触发它
					el[evName]();
				} else if (el.fireEvent) { //如果有触发事件的方法，就调用它
					el.fireEvent('on' + evName);
				} else if (document.createEvent) { //否则只好手动触发
					var evt = document.createEvent("MouseEvents"); //创建鼠标事件
					evt.initEvent(evName, true, true); //初始化 事件
					el.dispatchEvent(evt); //触发这个事件到此元素
				}
			}
		},
	};
</script>

<style lang='scss'>
	.nav-box {
		width: 100%;
		text-align: center;

		img {
			width: 200px;
			height: 100rpx;
			margin-top: 20px;
		}

		.tip {
			padding: 10px;
			color: red;
			font-size: 16px;
		}

		.btn {
			width: 50%;
			height: 60px;
			line-height: 60px;
			font-size: 16px;
			background-color: red;
			color: #fff;
			border-radius: 30rpx !important;
			margin-top: 20px;
			margin-left: 25%;
		}
	}
</style>